import Message from "@/store/messages";
import api from "@/store/api";
import { required, email, sameAs } from "vuelidate/lib/validators";
const reset_password = {
  namespaced: true,
  state: () => ({
    init: {
      email: null,
    },
    reset: {
      code: null,
      password: null,
    },
  }),
  mutations: {
    email(state, val) {
      state.init.email = val.toLowerCase();
    },
    code(state, val) {
      state.reset.code = val;
    },
    password(state, val) {
      state.reset.password = val;
    },
  },
  getters: {
    validation_email() {
      return {
        email: {
          required,
          email,
        },
      };
    },
    validation_reset(state) {
      return {
        password: {
          required,
        },
        password_check: {
          required,
          sameAs: sameAs(function () {
            return state.reset.password;
          }),
        },
      };
    },
  },
  actions: {
    start({ state, commit }) {
      return new Promise((resolve, reject) => {
        api
          .post("/users/password/start", state.init)
          .then((response) => {
            commit(
              "currentMsg",
              Message.getMessage("success", "sign_in.success.new_pw"),
              { root: true }
            );
            resolve(response.data.payload);
          })
          .catch((error) => {
            if (error.response.status == 400) {
              commit(
                "currentMsg",
                Message.getMessage("error", "error.unknown"),
                { root: true }
              );
            } else if (error.response.status == 404) {
              commit(
                "currentMsg",
                Message.getMessage("error", "reset_password.error.email"),
                { root: true }
              );
            } else {
              commit(
                "currentMsg",
                Message.getMessage("error", "error.unknown"),
                { root: true }
              );
            }
            reject(error);
          });
      });
    },
    finish({ state, commit }) {
      return new Promise((resolve, reject) => {
        api
          .post("/users/password/finish", state.reset)
          .then((response) => {
            commit(
              "currentMsg",
              Message.getMessage("success", "reset_password.success.updated"),
              { root: true }
            );
            resolve(response.data.payload);
          })
          .catch((error) => {
            if (error.response.status == 400) {
              commit(
                "currentMsg",
                Message.getMessage("error", "error.unknown"),
                { root: true }
              );
            } else if (error.response.status == 404) {
              commit(
                "currentMsg",
                Message.getMessage("error", "reset_password.error.not_found"),
                { root: true }
              );
            } else {
              commit(
                "currentMsg",
                Message.getMessage("error", "error.unknown"),
                { root: true }
              );
            }
            reject(error);
          });
      });
    },
  },
};
export default reset_password;
