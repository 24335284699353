import { helpers, required, email, sameAs } from "vuelidate/lib/validators";
import Message from "@/store/messages";
import api from "@/store/api";

const display_name = helpers.regex(
  "display_name",
  /^(?=[a-zA-Z0-9._]{4,20}$)(?!.*[_.]{2})[^_.].*[^_.]$/
);

const form = {
  namespaced: true,
  state: () => ({
    data: {
      email: "",
      password: "",
      first_name: "",
      last_name: "",
      display_name: "",
      country: "de_DE",
      privacy_policy: false,
      offset: {
        known_from: "UNKOWN",
        scope: "community",
        newsletter: false,
      },
      login_challenge: "",
    },
  }),
  mutations: {
    email(state, val) {
      state.data.email = val.toLowerCase();
    },
    password(state, val) {
      state.data.password = val;
    },
    display_name(state, val) {
      state.data.display_name = val;
    },
    first_name(state, val) {
      state.data.first_name = val;
    },
    last_name(state, val) {
      state.data.last_name = val;
    },
    country(state, val) {
      state.data.country = val;
    },
    privacy_policy(state, val) {
      state.data.privacy_policy = val;
    },
  },
  getters: {
    validations() {
      return {
        email: {
          required,
          email,
        },
        password: {
          required,
        },
        display_name: {
          required,
          display_name,
        },
        first_name: {
          required,
        },
        last_name: {
          required,
        },
        privacy_policy: {
          sameAs: sameAs(() => true),
        },
        password_check: {
          required,
          sameAs: sameAs(function () {
            return this.user.password;
          }),
        },
      };
    },
  },
  actions: {
    register({ state, commit }) {
      return new Promise((resolve, reject) => {
        api
          .post("/auth/register", state.data)
          .then((response) => {
            commit(
              "currentMsg",
              Message.getMessage("success", "sign_up.success.created"),
              { root: true }
            );
            resolve(response.data.payload);
          })
          .catch((error) => {
            if (error.response.status == 409) {
              commit(
                "currentMsg",
                Message.getMessage("warn", "sign_up.error.conflict"),
                { root: true }
              );
            } else if (error.response.status == 400) {
              if (error.response.data[0].Key == "'Register.DisplayName'") {
                commit(
                  "currentMsg",
                  Message.getMessage("error", "sign_up.error.username"),
                  { root: true }
                );
              } else {
                commit(
                  "currentMsg",
                  Message.getMessage("error", "sign_up.error.password"),
                  { root: true }
                );
              }
            } else {
              commit(
                "currentMsg",
                Message.getMessage("error", "defaults.error.unknown"),
                { root: true }
              );
            }
            reject(error);
          });
      });
    },
  },
};
export default form;
