<template>
  <div class="tabs-details">
    <vca-card class="form-container">
      <div class="margin-center max-content">
        <img src="~@/assets/img/vca_logo_neutral.png" title="Viva con Agua" />
      </div>
      <vca-card v-if="flow == 'check'">
        <vca-card class="text-center shadowed">
          <h1>{{ $t("messages.login.check") }}</h1>
        </vca-card>
      </vca-card>

      <LoginForm v-if="flow == 'login'" @success="success" />

      <div class="vca-center text-center" v-if="flow == 'login'">
        <h2>
          <i18n path="sign.login.register" class="typo-veneer">
            <span class="link typo-veneer" @click="register">
              {{ $t("sign.register.title") }}
            </span>
          </i18n>
        </h2>
      </div>
      <ResetPasswordInitial v-if="flow == 'pw_reset'" />

      <NewToken v-if="flow == 'resend'" />

      <vca-field-row>
        <button
          v-if="flow != 'login' && flow != 'check'"
          @click="flow = 'login'"
          class="vca-button-small"
        >
          {{ $t("sign.login.title") }}
        </button>

        <button
          v-if="flow != 'pw_reset' && flow != 'check'"
          @click="flow = 'pw_reset'"
          class="vca-button-small"
        >
          {{ $t("sign.login.reset_pw") }}
        </button>

        <button
          v-if="flow != 'resend' && flow != 'check'"
          @click="flow = 'resend'"
          class="vca-button-small"
        >
          {{ $t("sign.login.new_token") }}
        </button>
      </vca-field-row>
    </vca-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import LoginForm from "@/components/LoginForm";
import ResetPasswordInitial from "@/components/ResetPasswordInitial";
import NewToken from "@/components/NewToken";
export default {
  name: "LoginPage",
  components: { LoginForm, NewToken, ResetPasswordInitial },
  props: {
    login_challenge: {
      type: String,
      default: "",
    },
  },
  computed: {
    ...mapGetters({
      callback: "callback",
    }),
  },
  data() {
    return {
      flow: "login",
    };
  },
  created() {
    this.flow = "check";
    if (this.login_challenge !== "") {
      this.$store.commit("login/login_challenge", this.login_challenge);
      this.login();
    } else {
      this.refresh();
    }
  },
  methods: {
    register() {
      this.$router.push({
        name: "Register",
        query: {
          login_challenge: this.$route.query.login_challenge,
          language: this.$route.query.language,
        },
      });
    },
    success() {
      if (this.login_challenge !== "") {
        this.login();
      } else if (this.callback) {
        window.location.href = window.atob(this.callback);
      } else {
        this.$router.push({ path: "/apps" });
      }
    },
    login() {
      this.$store
        .dispatch("login/login_challenge")
        .then((response) => {
          if (response.redirect_to !== undefined) {
            window.location = response.redirect_to;
          } else if (this.callback) {
            window.location.href = window.atob(this.callback);
          } else {
            this.$router.push({ path: "/apps" });
          }
        })
        .catch(() => (this.flow = "login"));
    },
    refresh() {
      this.$store
        .dispatch("login/refresh")
        .then(() => {
          if (!this.callback) {
            this.$router.push({ path: "/apps" });
          } else {
            window.location.href = window.atob(this.callback);
          }
        })
        .catch((error) => {
          if (error != null) {
            this.notification(error);
          }
        })
        .finally(() => (this.flow = "login"));
    },
  },
};
</script>
