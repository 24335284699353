import Vue from "vue";
import Router from "vue-router";
import RegisterPage from "@/views/RegisterPage";
import LoginPage from "@/views/LoginPage";
import WebAppPage from "@/views/WebAppPage";
import store from "./store";
Vue.use(Router);
/**
 * All routes
 * You can pass a "meta" parameter, that contains {{ 'roles' : [] }}. The content of `roles` can be a string of the values
 * "Admin", "Employee" or "Supporter" or a complex JSON with the keys "name", "crew" and "pillar". For example (all
 * attributes except "name" are optional):
 * {{
 *  "name": "VolunteerManager",
 *  "pillar": "education", // "operation", "network", "finance"
 *  "crew" : {
 *    "name": "VcA Berlin",
 *    "id": "<some UUID>"
 *  }
 * }}
 * @type {VueRouter}
 */
function loadView(view) {
  return () =>
    import(/* webpackChunkName: '[request]' */ `@/views/${view}.vue`);
}

const routes = [
  {
    path: "/",
    name: "LandingPage",
    component: LoginPage,
    meta: { title: "navigation.login", visible: false },
  },
  {
    path: "/apps",
    name: "WebAppPage",
    component: WebAppPage,
    meta: { session: true, title: "navigation.apps" },
    props: (route) => ({
      login_challenge: route.query.login_challenge,
      language: route.query.language,
      msg: route.query.msg,
      source: route.query.source,
    }),
  },
  {
    path: "/login",
    name: "Login",
    component: LoginPage,
    meta: { session: false, title: "navigation.login" },
    props: (route) => ({
      login_challenge: route.query.login_challenge,
      language: route.query.language,
      msg: route.query.msg,
      source: route.query.source,
    }),
  },
  {
    path: "/consent",
    name: "Consent",
    component: loadView("ConsentPage"),
    meta: { session: false, title: "ConsentPage", visible: false },
    props: (route) => ({
      consent_challenge: route.query.consent_challenge,
    }),
  },
  {
    path: "/register",
    name: "Register",
    component: RegisterPage,
    meta: { session: false, title: "navigation.register" },
    props: (route) => ({
      login_challenge: route.query.login_challenge,
      language: route.query.language,
    }),
  },
  {
    path: "/confirm/:code",
    name: "Confirm",
    component: loadView("Confirm"),
    meta: { visible: false },
    props: true,
  },
  {
    path: "/reset/:code",
    name: "PasswordReset",
    component: loadView("ResetPassword"),
    meta: { session: false, visible: false },
    props: true,
  },
  {
    path: "/password/edit",
    name: "PasswordEdit",
    component: loadView("PasswordEdit"),
    meta: { session: true, visible: false },
  },
  {
    path: "/email/edit",
    name: "EmailEdit",
    component: loadView("EmailEdit"),
    meta: { session: true, visible: false },
  },
  {
    path: "/email/confirm/:code",
    name: "EmailConfirm",
    component: loadView("EmailConfirm"),
    meta: { session: true, visible: false },
    props: true,
  },
  {
    path: "/user/edit",
    name: "UserEdit",
    component: loadView("UserEdit"),
    meta: { session: true, visible: false },
  },
  {
    path: "/user",
    name: "ProfileView",
    component: loadView("Profile"),
    meta: { session: true, title: "navigation.profile" },
  },
  {
    path: "/logout",
    name: "Logout",
    meta: { session: true, title: "navigation.logout" },
    component: loadView("Logout"),
  },
  {
    path: "*",
    redirect: { name: "Register" },
    meta: { visible: false },
  },
];

var router = new Router({
  mode: "history",
  routes,
  scrollBehavior(to) {
    if (to.hash) {
      return { selector: to.hash };
    }
    return { x: 0, y: 0 };
  },
});
router.beforeEach((to, from, next) => {
  if (!store.state.scope && to.query.scope) {
    store.state.scope = to.query.scope;
  }
  if (!store.state.callback && to.query.callback) {
    store.state.callback = to.query.callback;
  }

  if (!store.state.user.current) {
    if (!to.meta.session) {
      next();
    } else {
      store
        .dispatch("login/refresh")
        .then(() => {
          next();
        })
        .catch(() => {
          router.push({ path: "/login" });
        });
    }
  } else {
    next();
  }
});
export default router;
