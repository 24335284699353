import Message from "@/store/messages";
import api from "@/store/api";
const confirmation = {
  namespaced: true,
  state: () => ({
    code: null,
    reset: {
      email: "",
    },
  }),
  mutations: {
    code(state, val) {
      state.code = val;
    },
    reset(state, val) {
      state.reset.email = val;
    },
  },
  actions: {
    reset({ state, commit }) {
      return new Promise((resolve, reject) => {
        api
          .post("/auth/register/reset", state.reset)
          .then((response) => {
            if (response.status == 200) {
              commit(
                "currentMsg",
                Message.getMessage("success", "sign_up.success.new_token"),
                { root: true }
              );
            }
            resolve(response.data.payload);
          })
          .catch((error) => {
            if (error.response.status == 404) {
              commit(
                "currentMsg",
                Message.getMessage("error", "reset_password.error.email"),
                { root: true }
              );
            } else if (error.response.status == 409) {
              commit(
                "currentMsg",
                Message.getMessage(
                  "error",
                  "reset_password.error.already_confirmed"
                ),
                { root: true }
              );
            } else {
              commit(
                "currentMsg",
                Message.getMessage("error", "defaults.error.unknown"),
                { root: true }
              );
            }
            reject(error);
          });
      });
    },
    submit({ state, commit }) {
      return new Promise((resolve, reject) => {
        api
          .get("/auth/register/confirm/" + state.code)
          .then((response) => {
            if (response.status == 200) {
              commit(
                "currentMsg",
                Message.getMessage("success", "sign_up.success.confirmed"),
                { root: true }
              );
            }
            resolve(response);
          })
          .catch((error) => {
            if (error.response.status == 404) {
              commit(
                "currentMsg",
                Message.getMessage("error", "reset_password.error.email"),
                { root: true }
              );
            } else if (error.response.status == 409) {
              commit(
                "currentMsg",
                Message.getMessage(
                  "error",
                  "reset_password.error.already_confirmed"
                ),
                { root: true }
              );
            } else {
              commit(
                "currentMsg",
                Message.getMessage("error", "defaults.error.unknown"),
                { root: true }
              );
            }
            reject(error);
          });
      });
    },
  },
};
export default confirmation;
