export default class Message {
  constructor() {}

  static getMessage(type, message) {
    return {
      title: "messages.title." + type,
      body: "messages." + message,
      type: type,
    };
  }
}
