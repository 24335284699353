import api from "@/store/api.js";
const webapp = {
  namespaced: true,
  state: () => ({
    list: [],
  }),
  mutations: {
    list(state, val) {
      state.list = val;
    },
  },
  getters: {
    list(state) {
      return state.list;
    },
  },
  actions: {
    list({ state, commit }) {
      return new Promise((resolve, reject) => {
        api
          .get("/webapps", state.challenge)
          .then((response) => {
            commit("list", response.data.payload);
            resolve(response.data.payload);
          })
          .catch((error) => {
            if (error.response.status == 400) {
              reject(null);
            }
            reject(error);
          });
      });
    },
  },
};
export default webapp;
