import { required, email } from "vuelidate/lib/validators";
import Message from "@/store/messages";
import api from "@/store/api";
const credentials = {
  namespaced: true,
  state: () => ({
    data: {
      email: null,
      password: null,
      login_challenge: null,
    },
  }),
  mutations: {
    email(state, val) {
      state.data.email = val.toLowerCase();
    },
    password(state, val) {
      state.data.password = val;
    },
    login_challenge(state, val) {
      state.data.login_challenge = val;
    },
  },
  getters: {
    validations() {
      return {
        email: {
          required,
          email,
        },
        password: {
          required,
        },
      };
    },
  },
  actions: {
    submit({ state, commit }) {
      return new Promise((resolve, reject) => {
        api
          .post("/auth/login", state.data)
          .then((response) => {
            commit("user/current", response.data.payload, {
              root: true,
            });
            resolve(response.data.payload);
          })
          .catch((error) => {
            if (error.response.status == 404) {
              commit(
                "currentMsg",
                Message.getMessage("error", "sign_in.error.email"),
                { root: true }
              );
            } else if (
              error.response.status == 400 &&
              error.response.data.message == "invalid_password"
            ) {
              commit(
                "currentMsg",
                Message.getMessage("error", "sign_in.error.password"),
                { root: true }
              );
            } else if (
              error.response.status == 400 &&
              error.response.data.message == "not_confirmed"
            ) {
              commit(
                "currentMsg",
                Message.getMessage("error", "sign_in.error.not_confirmed"),
                { root: true }
              );
            } else {
              commit(
                "currentMsg",
                Message.getMessage("error", "error.unknown"),
                { root: true }
              );
            }
            reject(error);
          });
      });
    },
  },
};
export default credentials;
