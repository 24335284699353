import api from "@/store/api.js";
import Message from "@/store/messages";
import axios from "axios";
import credentials from "./credentials";
import reset_password from "./reset_password";
const login = {
  namespaced: true,
  modules: {
    credentials: credentials,
    reset_password: reset_password,
  },
  state: () => ({
    challenge: {
      challenge: null,
    },
    user: null,
    consent: {},
  }),
  mutations: {
    login_challenge(state, val) {
      state.credentials.data.login_challenge = val;
      state.challenge.challenge = val;
    },
    consent_challenge(state, val) {
      state.challenge.challenge = val;
    },
    user(state, val) {
      state.user = val;
    },
    consent(state, val) {
      state.consent = val;
    },
  },
  actions: {
    login_challenge({ state, commit }) {
      return new Promise((resolve, reject) => {
        api
          .post("/auth/login_challenge", state.challenge)
          .then((response) => {
            resolve(response.data.payload);
          })
          .catch((error) => {
            if (
              (error.response.status == 401 || error.response.status == 400) &&
              (error.response.data.message == "missing or malformed jwt" ||
                error.response.data.message == "invalid or expired jwt")
            ) {
              reject(error);
            } else {
              commit(
                "currentMsg",
                Message.getMessage("error", "error.unknown"),
                {
                  root: true,
                }
              );
              reject(error);
            }
          });
      });
    },
    consent_challenge({ state }) {
      return new Promise((resolve, reject) => {
        api
          .post("/auth/consent_challenge", state.challenge)
          .then((response) => {
            resolve(response.data.payload);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    consent({ state }) {
      return new Promise((resolve, reject) => {
        api
          .post("/auth/consent", state.consent)
          .then((response) => {
            resolve(response.data.payload);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    refresh({ commit }) {
      var api_refresh = axios.create({
        baseURL: process.env.VUE_APP_BACKEND_URL,
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
      });
      return new Promise((resolve, reject) => {
        api_refresh
          .get("/auth/refresh")
          .then((response) => {
            commit("user/current", response.data.payload, {
              root: true,
            });
            resolve(response.data.payload);
          })
          .catch((error) => {
            if (
              error.response.status == 401 ||
              error.response.data.message == "missing or malformed jwt"
            ) {
              reject(null);
            }
            reject(error);
          });
      });
    },
    logout() {
      return new Promise((resolve, reject) => {
        api
          .get("/auth/logout")
          .then((response) => {
            resolve(response.data.payload);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
};
export default login;
