<template>
  <div class="page-center text-left">
    <vca-card class="form-center">
      <form>
        <h3>{{ $t("sign.login.email.label") }}</h3>
        <vca-input
          ref="email"
          type="email"
          :errorMsg="$t('sign.reset_pw.email.error')"
          :placeholder="$t('sign.reset_pw.email.placeholder')"
          v-model.trim="email"
          :rules="$v.email"
        >
        </vca-input>
        <button class="vca-button" @click.prevent="validate">
          {{ $t("sign.login.reset_pw") }}
        </button>
      </form>
    </vca-card>
  </div>
</template>
<script>
export default {
  name: "ResetPassword",
  computed: {
    email: {
      get() {
        return this.$store.state.login.reset_password.init.email;
      },
      set(value) {
        this.$store.commit("login/reset_password/email", value);
      },
    },
  },
  validations() {
    return this.$store.getters["login/reset_password/validation_email"];
  },
  methods: {
    validate() {
      if (this.$v.$invalid === true) {
        this.$refs.email.validate();
      } else {
        this.submit();
      }
    },
    submit() {
      this.$store.dispatch({ type: "login/reset_password/start" });
    },
  },
};
</script>
<style scoped>
.button {
  margin-top: 1em;
  margin-bottom: 1em;
  width: 100%;
}
</style>
