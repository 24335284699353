<template>
  <div class="tabs-details">
    <WebAppSelection />
  </div>
</template>
<script>
import WebAppSelection from "@/components/WebAppSelection";
export default {
  name: "WebAppPage",
  components: {
    WebAppSelection,
  },
};
</script>
