<template>
  <div id="navBarContainer" class="navigation-container">
    <div id="header" class="mobile-header">
      <div class="logo-container">
        <a href="/" class="logo">
          <img class="navigation-logo" src="~@/assets/img/vca_logo_plain.png" />
        </a>
      </div>
      <div class="language-container-mobile">
        <div class="language-fit">
          <LanguageSelection />
        </div>
      </div>
      <div class="burger-menu-icon" @click="toggleMenu()">
        <div id="burger-top-bar" class="burger-bar burger-bar-appear"></div>
        <div
          id="burger-middle-bar-1"
          class="burger-bar burger-bar-display"
        ></div>
        <div id="burger-middle-bar-2" class="burger-bar burger-bar-hide"></div>
        <div id="burger-bottom-bar" class="burger-bar burger-bar-appear"></div>
      </div>
    </div>
    <div id="navContainer" class="navContainer">
      <div class="custom-nav-bar">
        <ul id="menuList" class="menu-inactive">
          <li
            v-for="route in navigationList"
            @click="navigate(route)"
            :key="route.name"
          >
            <router-link
              :title="$t(route.meta.title)"
              :to="route.path"
              :class="{ 'is-active': isActive(route) }"
              >{{ $t(route.meta.title) }}</router-link
            >
            <div
              class="secondSubMenu"
              v-if="route.children && current_route.name == route.name"
            >
              <router-link
                @click="toggleMenu()"
                v-for="subTab in subNavigation(route)"
                :key="subTab.name"
                :title="$t(subTab.meta.title)"
                :to="subTab.path"
                >{{ $t(subTab.meta.title) }}</router-link
              >
            </div>
          </li>
          <li class="close-tabs" @click="toggleMenu()"><a>X</a></li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import LanguageSelection from "@/components/utils/LanguageSelection";

export default {
  name: "Navigation",
  components: {
    LanguageSelection,
  },
  data() {
    return {
      current_route: {},
    };
  },
  methods: {
    subNavigation(route) {
      return route.children.filter((route) => {
        // Check for correct meta
        if (route.meta == undefined) {
          route.meta = { title: "Missing identifier" };
          return true;
        }

        // Translate title
        route.meta.title = this.$t(route.meta.title);

        // Check visibility
        if (route.meta.visible != undefined && !route.meta.visible) {
          return false;
        }

        // Check permissions
        if (route.meta.permissions != undefined) {
          if (!this.session) {
            return false;
          }

          if (this.hasSystemPermission()) {
            return true;
          }

          if (this.hasPoolPermission()) {
            var roles = this.pool_roles.find((el) =>
              route.meta.permissions.includes(el.name)
            );
            return roles != undefined;
          }
          return false;
        }

        // Check session
        if (route.meta.session != undefined) {
          if (
            (this.session && route.meta.session == false) ||
            (!this.session && route.meta.session == true)
          ) {
            return false;
          }
        }

        return true;
      });
    },
    isActive(route) {
      return route == this.current_route;
    },
    navigate(route) {
      if (this.current_route !== route) {
        this.current_route = route;
      } else {
        this.toggleMenu();
      }
    },
    toggleMenu() {
      const menuList = document.getElementById("menuList");
      const topBar = document.getElementById("burger-top-bar");
      const middleBar1 = document.getElementById("burger-middle-bar-1");
      const middleBar2 = document.getElementById("burger-middle-bar-2");
      const bottomBar = document.getElementById("burger-bottom-bar");
      if (menuList.classList.contains("menu-inactive")) {
        window.scrollTo(0, 0);
        menuList.classList.remove("menu-inactive");
        menuList.classList.add("menu-active");
        topBar.classList.remove("burger-bar-appear");
        topBar.classList.add("burger-bar-disappear");
        bottomBar.classList.remove("burger-bar-appear");
        bottomBar.classList.add("burger-bar-disappear");
        middleBar2.classList.remove("burger-bar-hide");
        middleBar2.classList.add("burger-bar-display");
        middleBar1.classList.add("burger-bar-rotate-1");
        middleBar2.classList.add("burger-bar-rotate-2");
      } else {
        menuList.classList.remove("menu-active");
        menuList.classList.add("menu-inactive");
        topBar.classList.add("burger-bar-appear");
        topBar.classList.remove("burger-bar-disappear");
        bottomBar.classList.add("burger-bar-appear");
        bottomBar.classList.remove("burger-bar-disappear");
        middleBar2.classList.add("burger-bar-hide");
        middleBar2.classList.remove("burger-bar-display");
        middleBar1.classList.remove("burger-bar-rotate-1");
        middleBar2.classList.remove("burger-bar-rotate-2");
      }
    },
  },
  computed: {
    ...mapGetters({
      session: "session",
      current: "navigation/current",
      tabs: "navigation/tabs",
      hasSystemPermission: "user/roles/hasSystemPermission",
    }),
    navigationList() {
      return this.$router.options.routes.filter((route) => {
        // Check for correct meta
        if (route.meta == undefined) {
          route.meta = { title: "Missing identifier" };
          return true;
        }
        // Translate title
        route.meta.title = this.$t(route.meta.title);

        // Check visibility
        if (route.meta.visible != undefined && !route.meta.visible) {
          return false;
        }

        // Check permissions
        if (route.meta.permissions != undefined) {
          if (!this.session) {
            return false;
          }

          if (this.hasSystemPermission()) {
            return true;
          }
          return false;
        }

        // Check session
        if (route.meta.session != undefined) {
          if (
            (this.session && route.meta.session == false) ||
            (!this.session && route.meta.session == true)
          ) {
            return false;
          }
        }

        return true;
      });
    },
  },
};
</script>
<style lang="scss">
.mobile-header {
  position: fixed;
  display: flex;
  height: 50px;
  width: 100%;
  z-index: 100;
  background-color: $blue;

  .logo-container {
    order: 0;
    flex-grow: 1;
    padding-left: 10px;
    display: flex;

    .logo {
      cursor: pointer;

      img {
        margin-top: 8px;
        max-height: 38px;
        width: auto;
        margin-left: 5px;
      }
    }
  }

  .language-container-mobile {
    display: flex;
    width: max-content;
    margin: auto;
    padding-right: 10px;
    order: 1;
    flex-grow: 0;

    span.normal-flag {
      transform: scale(0.35);
      -ms-transform: scale(0.35);
      -webkit-transform: scale(0.35);
      -moz-transform: scale(0.35);
      margin: -15px -20px;
    }

    span.small-flag {
      transform: scale(0.2);
      -ms-transform: scale(0.2);
      -webkit-transform: scale(0.2);
      -moz-transform: scale(0.2);
      margin: -15px -25px;
    }
  }

  .burger-menu-icon {
    position: relative;
    margin: auto;
    display: block;
    order: 2;
    flex-grow: 0;
    padding: 0 15px 0 15px;
    cursor: pointer;

    .burger-bar {
      width: 20px;
      height: 3px;
      background-color: $vca-primary-dark;
      margin: 4px 0 4px 0;
      border-radius: 2px;
      transition: all 0.2s;
    }

    .burger-bar-display {
      display: block;
    }
    .burger-bar-hide {
      display: none;
    }
    .burger-bar-appear {
      opacity: 1;
    }
    .burger-bar-disappear {
      opacity: 0;
    }
    .burger-bar-rotate-1 {
      position: absolute;
      bottom: 3px;
      transform: rotate(45deg);
    }
    .burger-bar-rotate-2 {
      position: absolute;
      bottom: 3px;
      transform: rotate(-45deg);
    }
  }
}

.navContainer {
  position: relative;

  .custom-nav-bar {
    ul {
      position: absolute;
      z-index: 99;
      border-top: solid thin $vca-primary-dark;
      border-bottom: solid thin $white;
      padding: 0;
      -moz-box-pack: center;
      margin-top: 50px;
      justify-content: center;
      list-style-type: none;
      display: block;
      transition: all 0.4s;
      width: 50%;
      top: 0;
      right: 0;
      background-color: $blue;

      &.menu-inactive {
        visibility: hidden;
        opacity: 0;
        transform: translateY(10px);
      }
      &.menu-active {
        visibility: visible;
        box-sizing: border-box;
        position: absolute;
        width: 100%;
        opacity: 1;
      }

      li {
        flex: auto;

        a {
          color: $white;
          cursor: pointer;
          display: block;
          padding: 10px 10px;
          text-align: center;
          text-decoration: none;

          &:hover:not(.router-link-active) {
            color: $vca-primary-dark;
          }
          &:hover {
            color: $blue;
          }
          &.router-link-active {
            background-color: $vca-primary-dark;
            &.is-active {
              border-bottom: solid thin $white;
            }
          }
        }

        &.close-tabs {
          background-color: $vca-primary-dark;

          a {
            padding: 0;
          }
        }
      }
    }
  }
}

.secondSubMenu {
  position: relative;
  transition: all 0.3s;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: auto;
  background: $vca-primary-dark;
  box-sizing: border-box;

  a {
    &:hover {
      color: $blue !important;
    }
    &.router-link-exact-active {
      border-bottom: none !important;
      font-weight: bolder;

      &:before {
        content: "> ";
      }
      &:after {
        content: " <";
      }
    }
  }
}
</style>
