<template>
  <div id="app">
    <vca-loading v-if="loadingFlow" />
    <notifications position="top center" width="100%" />

    <TopNavigation class="desktop-view" />
    <MobileNavigation class="mobile-view" />
    <router-view id="app-content" />
    <Footer id="app-footer" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import TopNavigation from "@/components/layout/TopNavigation";
import MobileNavigation from "@/components/layout/MobileNavigation";
import Footer from "@/components/layout/Footer.vue";
export default {
  name: "Auth",
  components: {
    Footer,
    TopNavigation,
    MobileNavigation,
  },
  computed: {
    ...mapGetters({
      session: "session",
      loadingFlow: "loadingFlow",
      currentMsg: "currentMsg",
    }),
  },
  watch: {
    currentMsg: function (currentMsg) {
      if (currentMsg !== null) {
        this.notification(currentMsg);
        this.$store.commit("currentMsg", null);
      }
    },
    $route: function () {
      if (this.loadingFlow) {
        this.$store.commit("loadingFlow");
      }
    },
  },
  created() {
    if (this.$i18n) {
      if (localStorage.language === undefined) {
        localStorage.language = this.$i18n.locale.toLowerCase();
      } else {
        this.$i18n.locale = localStorage.language;
      }
    }
    if (this.loadingFlow) {
      this.$store.commit("loadingFlow");
    }
    if (this.currentMsg !== null) {
      this.notification(this.currentMsg);
      this.$store.commit("currentMsg", null);
    }
  },
};
</script>

<style lang="scss">
.desktop-view {
  display: block !important;
  @include media(small) {
    display: none !important;
  }
}
.mobile-view {
  display: none !important;
  @include media(small) {
    display: contents !important;
  }
}
.mobile-block-view {
  display: none !important;
  @include media(small) {
    display: block !important;
  }
}
#app {
  font-family: "Roboto Slab";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #555555;
  @include media(small) {
    font-size: 0.8em;
  }
}
.link {
  color: $main-color;
  cursor: pointer;
  text-decoration: underline;
}
.margin-center {
  margin: auto;
}
.max-content {
  width: max-content;
}
.clickable {
  cursor: pointer;
}
.page-center {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  .form-center {
    width: 80%;
    display: flex;
    flex-direction: column;
    margin: auto;
    @include media(large) {
      width: 50%;
    }
  }
}
</style>
