<template>
  <div class="tabs-details">
    <vca-card>
      <RegisterForm />

      <div class="vca-center text-center">
        <h2>
          <i18n path="sign.register.login" class="typo-veneer">
            <span class="link typo-veneer" @click="login">
              {{ $t("sign.login.title") }}
            </span>
          </i18n>
        </h2>
      </div>
    </vca-card>
  </div>
</template>

<script>
import RegisterForm from "@/components/RegisterForm";
export default {
  name: "RegisterPage",
  props: {
    login_challenge: {
      type: String,
      default: "",
    },
    language: {
      type: String,
      default: "de",
      validator: function (value) {
        return ["de", "en"].indexOf(value) !== -1;
      },
    },
  },
  created() {
    this.$i18n.locale = this.$route.query.language;
    localStorage.language = this.$i18n.locale;
    this.refresh();
    if (this.$route.query.login_challenge !== "") {
      this.$store.commit(
        "register/login_challenge",
        this.$route.query.login_challenge
      );
    }
  },
  components: {
    RegisterForm,
  },
  methods: {
    login() {
      this.$router.push({
        name: "Login",
        query: {
          login_challenge: this.$route.query.login_challenge,
          language: this.$route.query.language,
        },
      });
    },
    refresh() {
      this.$store.dispatch("login/refresh").then(() => {
        this.$router.push({ path: "/login" });
      });
    },
  },
};
</script>
