<template>
  <div class="text-left">
    <h1>{{ $t("webapps.header") }}</h1>
    <p>{{ $t("webapps.description") }}</p>
    <div v-if="list.length > 0" class="cards-container">
      <vca-card
        class="shadowed clickable app-card"
        v-for="item in list"
        :key="item.id"
        @click.native="redirect(item.login_redirect_url)"
      >
        <vca-row class="text-center">
          <h2>
            {{ item.name }}
          </h2>
        </vca-row>
      </vca-card>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "WebAppSelection",
  computed: {
    ...mapGetters({
      list: "webapp/list",
    }),
  },
  methods: {
    redirect(url) {
      window.location.href = url;
    },
  },
  created() {
    this.$store.dispatch("webapp/list").catch(() => {
      this.$router.push("login");
    });
  },
};
</script>
<style lang="scss">
.cards-container {
  display: grid;

  @include media(large) {
    grid-template-columns: 33.33% 33.33% 33.33%;
  }

  & > div {
    &:hover {
      transition: $transition;
      background-color: $blue;
      h2 {
        color: white;
      }
    }
    padding: 5px 0;
    @include media(large) {
      padding: 10px;
    }
    & > div {
      padding: 10px;
    }
  }
}
</style>
