import Vue from "vue";
import Vuex from "vuex";
import register from "./register";
import login from "./login";
import user from "./user";
import webapp from "./webapp";
import createPersistedState from "vuex-persistedstate";
Vue.use(Vuex);
export default new Vuex.Store({
  plugins: [
    createPersistedState({
      storage: window.sessionStorage,
      reducer(val) {
        return {
          //Save only the user in state
          user: val.user,
        };
      },
    }),
  ],
  modules: {
    namespaced: true,
    register: register,
    login: login,
    user: user,
    webapp: webapp,
  },
  state: {
    loading: false,
    scope: null,
    currentMsg: null,
    callback: null,
  },
  getters: {
    session(state) {
      if (state.user.current !== null) {
        return true;
      }
      return false;
    },
    callback(state) {
      return state.callback;
    },
    currentMsg(state) {
      return state.currentMsg;
    },
    loadingFlow(state) {
      return state.loading;
    },
    scope(state) {
      return state.scope;
    },
  },
  mutations: {
    callback(state, value) {
      state.callback = value;
    },
    currentMsg(state, value) {
      state.currentMsg = value;
    },
    loadingFlow(state) {
      state.loading = !state.loading;
    },
    scope(state, value) {
      state.scope = value;
    },
  },
});
