import api from "@/store/api.js";
import Message from "@/store/messages";
import { sameAs, helpers, required } from "vuelidate/lib/validators";

const display_name = helpers.regex(
  "display_name",
  /^(?=[a-zA-Z0-9._]{4,20}$)(?!.*[_.]{2})[^_.].*[^_.]$/
);
const user = {
  namespaced: true,
  state: () => ({
    current: null,
    reset_password: {
      password: null,
    },
    reset_email: {
      email: null,
      code: null,
    },
  }),
  mutations: {
    current(state, value) {
      state.current = value;
    },
    reset_password(state, val) {
      state.reset_password.password = val;
    },
    reset_email(state, val) {
      state.reset_email.email = val;
    },
    reset_email_code(state, val) {
      state.reset_email.code = val;
    },
  },
  getters: {
    current(state) {
      return state.current;
    },
    validation() {
      return {
        current: {
          display_name: {
            required,
            display_name,
          },
          first_name: {
            required,
          },
          last_name: {
            required,
          },
        },
      };
    },
    validation_reset(state) {
      return {
        password: {
          required,
        },
        password_check: {
          required,
          sameAs: sameAs(function () {
            return state.reset_password.password;
          }),
        },
      };
    },
    validation_email() {
      return {
        email: {
          required,
        },
      };
    },
  },
  actions: {
    submit({ state, commit }) {
      return new Promise((resolve, reject) => {
        api
          .put("/users", state.current)
          .then((response) => {
            commit("user/current", response.data.payload, {
              root: true,
            });
            commit(
              "currentMsg",
              Message.getMessage("success", "user.success.updated"),
              { root: true }
            );
            resolve(response.data.payload);
          })
          .catch((error) => {
            if (error.response.status == 404) {
              commit(
                "currentMsg",
                Message.getMessage("error", "user.error.not_found"),
                { root: true }
              );
            } else {
              commit(
                "currentMsg",
                Message.getMessage("error", "defaults.error.unknown"),
                { root: true }
              );
            }
            reject(error);
          });
      });
    },
    reset_password({ state, commit }) {
      return new Promise((resolve, reject) => {
        api
          .put("/users/password", state.reset_password)
          .then((response) => {
            commit(
              "currentMsg",
              Message.getMessage("success", "user.success.password_reset"),
              { root: true }
            );
            resolve(response.data.payload);
          })
          .catch((error) => {
            if (error.response.status == 400) {
              commit(
                "currentMsg",
                Message.getMessage("error", "error.unknown"),
                { root: true }
              );
            } else if (error.response.status == 404) {
              commit(
                "currentMsg",
                Message.getMessage("error", "user.error.not_found"),
                { root: true }
              );
            } else {
              commit(
                "currentMsg",
                Message.getMessage("error", "error.unknown"),
                { root: true }
              );
            }
            reject(error);
          });
      });
    },
    email_start({ state, commit }) {
      return new Promise((resolve, reject) => {
        api
          .post("/users/email/start", state.reset_email)
          .then((response) => {
            if (response.status == 201) {
              commit(
                "currentMsg",
                Message.getMessage(
                  "success",
                  "user.success.password_email_start"
                ),
                { root: true }
              );
            }
            resolve(response.data.payload);
          })
          .catch((error) => {
            if (error.response.status == 400) {
              commit(
                "currentMsg",
                Message.getMessage("error", "error.unknown"),
                { root: true }
              );
            } else if (error.response.status == 404) {
              commit(
                "currentMsg",
                Message.getMessage("error", "user.error.not_found"),
                { root: true }
              );
            } else if (error.response.status == 409) {
              commit(
                "currentMsg",
                Message.getMessage("error", "user.error.duplicate"),
                { root: true }
              );
            } else {
              commit(
                "currentMsg",
                Message.getMessage("error", "error.unknown"),
                { root: true }
              );
            }
            reject(error);
          });
      });
    },
    email_finish({ state, commit }) {
      return new Promise((resolve, reject) => {
        api
          .post("/users/email/finish", state.reset_email)
          .then((response) => {
            if (response.status == 200) {
              commit(
                "currentMsg",
                Message.getMessage(
                  "success",
                  "user.success.password_email_finish"
                ),
                { root: true }
              );
            }
            resolve(response.data.payload);
          })
          .catch((error) => {
            if (error.response.status == 400) {
              commit(
                "currentMsg",
                Message.getMessage("error", "error.unknown"),
                { root: true }
              );
            } else if (error.response.status == 404) {
              commit(
                "currentMsg",
                Message.getMessage("error", "user.error.token_not_found"),
                { root: true }
              );
            } else if (error.response.status == 409) {
              commit(
                "currentMsg",
                Message.getMessage("error", "user.error.duplicate"),
                { root: true }
              );
            } else {
              commit(
                "currentMsg",
                Message.getMessage("error", "error.unknown"),
                { root: true }
              );
            }
            reject(error);
          });
      });
    },
  },
};
export default user;
