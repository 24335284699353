<template>
  <div class="page-center text-left">
    <vca-card class="form-center">
      <form>
        <vca-input
          ref="email"
          type="email"
          name="email"
          :errorMsg="$t('sign.register.email.error')"
          :placeholder="$t('sign.register.email.placeholder')"
          v-model.trim="email"
          :rules="$v.email"
        >
        </vca-input>

        <vca-row>
          <vca-input
            ref="display_name"
            name="display_name"
            :errorMsg="$t('sign.register.displayname.error')"
            :placeholder="$t('sign.register.displayname.placeholder')"
            v-model.trim="display_name"
            :rules="$v.display_name"
          >
          </vca-input>
          <div class="inline-infobox">
            <vca-info-box>{{
              $t("sign.register.displayname.description")
            }}</vca-info-box>
          </div>
        </vca-row>

        <vca-field-row>
          <vca-input
            ref="first_name"
            name="first_name"
            first
            :errorMsg="$t('sign.register.firstname.error')"
            :placeholder="$t('sign.register.firstname.placeholder')"
            v-model.trim="first_name"
            :rules="$v.first_name"
          >
          </vca-input>

          <vca-input
            ref="last_name"
            name="last_name"
            last
            :errorMsg="$t('sign.register.lastname.error')"
            :placeholder="$t('sign.register.lastname.placeholder')"
            v-model.trim="last_name"
            :rules="$v.last_name"
          >
          </vca-input>
        </vca-field-row>

        <vca-input
          ref="password"
          name="password"
          type="password"
          :errorMsg="$t('sign.register.password1.error')"
          :placeholder="$t('sign.register.password1.placeholder')"
          v-model.trim="password"
          :rules="$v.password"
        >
        </vca-input>

        <vca-input
          ref="password_check"
          name="password_check"
          type="password"
          :errorMsg="pwError"
          :placeholder="$t('sign.register.password2.placeholder')"
          v-model.trim="password_check"
          :rules="$v.password_check"
        >
        </vca-input>

        <div class="select-known">
          <vca-dropdown
            :label="$t('sign.register.known_from.label')"
            @input="changeKnown"
            :placeholder="$t('sign.register.known_from.placeholder')"
            ref="known_from"
            :options="known_options"
          />
        </div>

        <vca-checkbox
          ref="privacy_policy"
          v-model="privacy_policy"
          :rules="$v.privacy_policy"
          :errorMsg="$t('sign.register.privacy_policy.error')"
        >
          <i18n path="sign.register.privacy_policy.text">
            <a
              href="https://www.vivaconagua.org/datenschutzerklaerung"
              target="_blank"
            >
              {{ $t("sign.register.privacy_policy.privacy_policy") }}
            </a>
          </i18n>
        </vca-checkbox>

        <vca-checkbox v-model="user.offset.newsletter">
          <div class="highlight">{{ newsletterTitle }}</div>

          <div v-html="newsletterText"></div>
        </vca-checkbox>
        <button class="vca-button button" @click.prevent="validate">
          {{ $t("sign.register.title") }}
        </button>
      </form>
    </vca-card>
  </div>
</template>

<script>
export default {
  name: "Register",
  computed: {
    newsletterTitle() {
      return this.$t("sign.register.newsletter." + this.scope + ".title");
    },
    newsletterText() {
      return this.$t("sign.register.newsletter." + this.scope + ".text");
    },
    scope() {
      return this.$store.state.scope ? this.$store.state.scope : "community";
    },
    user: {
      get() {
        return this.$store.state.register.form.data;
      },
      set(value) {
        this.$store.commit("register/user", value);
      },
    },
    email: {
      get() {
        return this.$store.state.register.form.data.email;
      },
      set(value) {
        this.$store.commit("register/form/email", value);
      },
    },
    password: {
      get() {
        return this.$store.state.register.form.data.password;
      },
      set(value) {
        this.$store.commit("register/form/password", value);
      },
    },
    display_name: {
      get() {
        return this.$store.state.register.form.data.display_name;
      },
      set(value) {
        this.$store.commit("register/form/display_name", value);
      },
    },
    first_name: {
      get() {
        return this.$store.state.register.form.data.first_name;
      },
      set(value) {
        this.$store.commit("register/form/first_name", value);
      },
    },
    last_name: {
      get() {
        return this.$store.state.register.form.data.last_name;
      },
      set(value) {
        this.$store.commit("register/form/last_name", value);
      },
    },
    privacy_policy: {
      get() {
        return this.$store.state.register.form.data.privacy_policy;
      },
      set(value) {
        this.$store.commit("register/form/privacy_policy", value);
      },
    },

    pwError() {
      if (this.$v.password_check.required && !this.$v.password_check.sameAs) {
        return this.$t("sign.register.password2.error.same");
      }
      return this.$t("sign.register.password2.error.enter");
    },
  },
  data() {
    return {
      password_check: "",
      known_options: [
        {
          title: this.$t("sign.register.known_from.select"),
          label: this.$t("sign.register.known_from.select"),
          value: "",
        },
        {
          title: this.$t("sign.register.known_from.family_friends"),
          label: this.$t("sign.register.known_from.family_friends"),
          value: "family_friends",
        },
        {
          title: this.$t("sign.register.known_from.festivals_cupdonation"),
          label: this.$t("sign.register.known_from.festivals_cupdonation"),
          value: "festivals_cupdonation",
        },
        {
          title: this.$t("sign.register.known_from.water"),
          label: this.$t("sign.register.known_from.water"),
          value: "water",
        },
        {
          title: this.$t("sign.register.known_from.move4water"),
          label: this.$t("sign.register.known_from.move4water"),
          value: "move4water",
        },
        {
          title: this.$t("sign.register.known_from.instagram"),
          label: this.$t("sign.register.known_from.instagram"),
          value: "instagram",
        },
        {
          title: this.$t("sign.register.known_from.facebook"),
          label: this.$t("sign.register.known_from.facebook"),
          value: "facebook",
        },
      ],
    };
  },
  validations() {
    return this.$store.getters["register/form/validations"];
  },
  created() {
    this.$store.commit(
      "register/login_challenge",
      this.$route.query.login_challenge
    );
  },
  methods: {
    validate() {
      if (this.$v.$invalid === true) {
        this.$refs.email.validate();
        this.$refs.password.validate();
        this.$refs.first_name.validate();
        this.$refs.last_name.validate();
        this.$refs.display_name.validate();
        this.$refs.password_check.validate();
        this.$refs.privacy_policy.validate();
      } else {
        this.submit();
      }
    },
    submit() {
      //this.$store.commit('loadingFlow')
      this.$store.state.register.form.data.offset.scope = this.scope;
      this.$store.dispatch({ type: "register/form/register" }).then((data) => {
        var that = this;
        setTimeout(function () {
          if (data.hydra !== null && data.redirect_to != "") {
            window.location = data.redirect_to;
          } else {
            that.$router.push({ path: "/login" });
          }
        }, 3000);
      });
    },
    changeKnown(nVal) {
      if (nVal.length > 0) {
        this.user.offset.known_from = nVal[0].value;
      } else {
        this.user.offset.known_from = "UNKOWN";
      }
    },
  },
};
</script>
