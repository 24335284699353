<template>
  <div class="page-center text-left">
    <vca-card class="form-center">
      <form>
        <h3>{{ $t("sign.login.email.label") }}</h3>
        <vca-input
          ref="email"
          name="email"
          type="email"
          :errorMsg="$t('sign.login.email.error')"
          :placeholder="$t('sign.login.email.placeholder')"
          v-model.trim="email"
          :rules="$v.email"
        >
        </vca-input>

        <h3>{{ $t("sign.login.password.label") }}</h3>
        <vca-input
          ref="password"
          name="password"
          type="password"
          :errorMsg="$t('sign.login.password.error')"
          :placeholder="$t('sign.login.password.placeholder')"
          v-model.trim="password"
          :rules="$v.password"
        >
        </vca-input>

        <vca-field-row>
          <button class="vca-button button" @click.prevent="validate">
            {{ $t("sign.login.title") }}
          </button>
        </vca-field-row>
      </form>
    </vca-card>
  </div>
</template>

<script>
export default {
  name: "LoginForm",
  computed: {
    email: {
      get() {
        return this.$store.state.login.credentials.data.email;
      },
      set(value) {
        this.$store.commit("login/credentials/email", value);
      },
    },
    password: {
      get() {
        return this.$store.state.login.credentials.data.password;
      },
      set(value) {
        this.$store.commit("login/credentials/password", value);
      },
    },
  },
  validations() {
    return this.$store.getters["login/credentials/validations"];
  },
  created() {
    this.$i18n.locale = this.$route.query.language;
    if (this.$route.query.msg && this.$route.query.source) {
      this.notification({
        title: this.$t(
          "sign.login.msg." +
            this.$route.query.msg +
            "." +
            this.$route.query.source +
            ".title"
        ),
        body: this.$t(
          "sign.login.msg." +
            this.$route.query.msg +
            "." +
            this.$route.query.source +
            ".body"
        ),
        type: this.$t(
          "sign.login.msg." +
            this.$route.query.msg +
            "." +
            this.$route.query.source +
            ".type"
        ),
      });
    }
  },
  methods: {
    validate() {
      if (this.$v.$invalid === true) {
        this.$refs.email.validate();
        this.$refs.password.validate();
      } else {
        this.submit();
      }
    },
    submit() {
      this.$store.dispatch("login/credentials/submit").then(() => {
        this.$emit("success");
      });
    },
  },
};
</script>
